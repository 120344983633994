<template>
  <div>
    <h1>Formas de pago</h1>
    <v-list v-if="formasDePago.length > 0">
      <v-list-item v-for="fp in formasDePago" :key="fp.id">
        <v-list-item-content>
          <v-list-item-title>
            {{ fp.nombre }}
          </v-list-item-title>
        </v-list-item-content>
          <v-list-item-action>
      <v-icon> {{ fp.habilitado ? 'mdi-check' : 'mdi-close' }}</v-icon>
    </v-list-item-action>

      </v-list-item>
    </v-list>
   
  </div>
</template>

<script>
export default {
  name: "FormasDePagoSoporte",
  data() {
    return {
      formasDePago: [],
    };
  },
  methods: {
   
  },
  created() {
    this.axios
      .get("/comprobantes/formas-de-pago")
      .then((response) => {
        this.formasDePago = response.data;
      })
      .catch((e) => {
        console.log(e);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: `${e}`,
          icon: "mdi-information",
          timeout: 3000,
        });
      });
  },
  
};
</script>

<style lang="scss" scoped>
</style>